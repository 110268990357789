import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../contexts/AuthContext";
import useLogin from "../../services/useLogin";
import { IAuthContext } from "../../types/auth";
import styles from "./styles.module.css";
import {
  Alert,
  Box,
  Button,
  Collapse,
  TextField,
  Typography,
} from "@mui/material";

const Login = () => {
  const navigate = useNavigate();
  const { tokens } = useContext(AuthContext) as IAuthContext;
  const [form, setForm] = useState({ email: "", password: "" });
  const { isError, isLoading, mutate } = useLogin(form.email, form.password);

  useEffect(() => {
    if (tokens.access !== null && tokens.refresh !== null) {
      navigate("/", { replace: true });
    }
  }, [navigate, tokens]);

  const handleChange =
    (key: "email" | "password") => (e: ChangeEvent<HTMLInputElement>) => {
      setForm({
        ...form,
        [key]: e.target.value,
      });
    };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutate();
  };

  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          width: "100%",
        }}
      >
        <Collapse in={isError}>
          <Alert severity="error">
            Houve um problema ao fazer login, tente novamente.
          </Alert>
        </Collapse>
      </Box>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <Typography variant="h5" component="h1">
            Olá, parceira!
          </Typography>
          <Typography variant="body1" component="h1">
            Faça seu login para começar a montar sua cesta da semana!
          </Typography>
          <form className={styles.form} onSubmit={handleSubmit}>
            <TextField
              label="E-mail"
              onChange={handleChange("email")}
              value={form.email}
            />
            <TextField
              label="Senha"
              onChange={handleChange("password")}
              type="password"
              value={form.password}
            />
            <Button
              disabled={isLoading || form.email === "" || form.password === ""}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
            >
              Entrar
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
