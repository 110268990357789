import { FC } from "react";

import { IItem } from "../../types/cart";
import styles from "./styles.module.css";

interface Props {
  item: IItem;
}

const CartItem: FC<Props> = ({ item }) => {
  return (
    <>
      <div>{item.amount}</div>
      <div className={styles.item_name}>
        {item.product.base_product.name} - {item.product.producer.name}
      </div>
      <div>R$ {(item.amount * parseFloat(item.product.price)).toFixed(2)}</div>
    </>
  );
};

export default CartItem;
