import { FC, ReactNode, createContext } from "react";
import { omit } from "lodash";

import { ICart, ICartContext, IProduct } from "../types/cart";
import usePersistentState from "../hooks/usePersistentState";

export const CartContext = createContext<ICartContext | null>(null);

interface Props {
  children: ReactNode;
}

const CartProvider: FC<Props> = ({ children }) => {
  const [cart, setCart] = usePersistentState<ICart>("cart", {});

  const decreaseItem = (product: IProduct, slug: string) => {
    if (cart[slug] && cart[slug][product.id]) {
      if (cart[slug][product.id].amount - 1 <= 0) {
        setCart(omit(cart, `${slug}.${product.id}`));
      } else {
        setCart({
          ...cart,
          [slug]: {
            ...cart[slug],
            [product.id]: {
              ...cart[slug][product.id],
              amount: cart[slug][product.id].amount - 1,
            },
          },
        });
      }
    }
  };

  const getCart = (slug?: string) => {
    if (slug && cart[slug]) {
      return cart[slug];
    }

    return {};
  };

  const increaseItem = (product: IProduct, slug: string) => {
    if (!cart[slug]) {
      cart[slug] = {};
    }

    if (cart[slug][product.id]) {
      if (
        cart[slug][product.id].amount < product.stock ||
        product.unlimited_stock
      ) {
        setCart({
          ...cart,
          [slug]: {
            ...cart[slug],
            [product.id]: {
              ...cart[slug][product.id],
              amount: cart[slug][product.id].amount + 1,
            },
          },
        });
      }
    } else {
      setCart({
        ...cart,
        [slug]: {
          ...cart[slug],
          [product.id]: {
            amount: 1,
            product: product,
          },
        },
      });
    }
  };

  const emptyCart = () => {
    setCart({});
  };

  return (
    <CartContext.Provider
      value={{ decreaseItem, emptyCart, getCart, increaseItem }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
