import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { QrCodePix } from "qrcode-pix";

import styles from "./styles.module.css";
import useUrbanPartner from "../../services/useUrbanPartner";
import useSeed from "../../services/useSeed";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getWeekdayName } from "../../utils/weekdayUtils";

const Payment = () => {
  const [qrCode, setQrCode] = useState("");
  const [pixPayload, setPixPayload] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { isLoading: isLoadingUP } = useUrbanPartner();
  const { data: seed, isLoading: isLoadingSeed } = useSeed();
  const [searchParams] = useSearchParams();
  const { slug } = useParams();

  const totalParam = searchParams.get("total");
  const totalAmount =
    totalParam !== null ? parseFloat(totalParam).toFixed(2) : "0";
  const deliveryParam = searchParams.get("delivery");

  useEffect(() => {
    if (seed && searchParams.has("total")) {
      const totalParam = searchParams.get("total");

      if (totalParam) {
        const total = parseFloat(totalParam);
        const qrCodePix = QrCodePix({
          version: "01",
          key: seed.seeder.pix_key,
          value: total,
          city: "",
          name: "",
        });

        qrCodePix.base64().then((base64QrCode: string) => {
          setQrCode(base64QrCode);
          setPixPayload(qrCodePix.payload());
        });
      }
    }
  }, [searchParams, seed]);

  if (isLoadingUP || isLoadingSeed || !seed) {
    return <CircularProgress />;
  }

  if (!searchParams.has("total") || !searchParams.get("total")) {
    return (
      <div style={{ margin: "0 auto", maxWidth: "900px" }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Pagamento
        </Typography>
        <Typography variant="body1" gutterBottom>
          Valor total nulo ou não identificado, tente novamente.
        </Typography>
      </div>
    );
  }

  if (totalParam !== null && parseFloat(totalParam) === 0) {
    return (
      <div style={{ margin: "0 auto", maxWidth: "900px" }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Seu pedido já está pago
        </Typography>
        <Typography variant="body1">Pronto!</Typography>
        <Typography variant="body1">
          Agora sua semeadora cuidará da sua feira pra você.
        </Typography>
      </div>
    );
  }

  const deliveryWeekday = getWeekdayName(seed.delivery_weekday);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handlePIXCopyAndPasteClick = () => {
    navigator.clipboard.writeText(pixPayload);
    setTooltipOpen(true);
  };

  return (
    <div style={{ margin: "0 auto", maxWidth: "900px", padding: "2rem" }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Pagamento
      </Typography>
      <Typography variant="body1" gutterBottom>
        Escaneie o QR code ou copie o código PIX abaixo:
      </Typography>
      {qrCode !== "" && pixPayload !== "" ? (
        <div className={styles.qr_code}>
          {qrCode && <img alt="qr code" src={qrCode} />}
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copiado!"
              >
                <span onClick={handlePIXCopyAndPasteClick}>{pixPayload}</span>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </div>
      ) : null}
      <Typography variant="body1" gutterBottom>
        Confira os dados da sua semeadora:
      </Typography>
      <div className={styles.seeder}>
        <Typography variant="body2">{seed.seeder.name}</Typography>
        <Typography variant="body2">{seed.seeder.phone}</Typography>
      </div>
      <Typography variant="body1" component="div">
        Confira o valor da sua compra:
      </Typography>
      <div className={styles.seeder}>
        <Typography variant="body2" component="div">
          Total R$ {totalAmount}
        </Typography>
      </div>
      <Typography variant="body1">Pronto!</Typography>
      {deliveryParam === "delivery" && (
        <Typography variant="body1">
          A entrega está programada para: {deliveryWeekday}.
        </Typography>
      )}
      {deliveryParam === "takeout" && (
        <Typography variant="body1">
          O dia para retirada da sua feira é: {deliveryWeekday}.
        </Typography>
      )}
      <Typography variant="body1">
        Agora sua semeadora cuidará da sua feira pra você.
      </Typography>

      <Box textAlign="center">
        <Link to={`/${slug}`}>
          <Button className={styles.btn}>Voltar para a loja</Button>
        </Link>
      </Box>
    </div>
  );
};

export default Payment;
